/* Import Font AirPayOne */
@font-face {
  font-family: OrbitronBold;
  src: url(./assets//fonts/Orbitron-Bold.ttf);
}

@font-face {
  font-family: OrbitronMedium;
  src: url(./assets//fonts/Orbitron-Medium.ttf);
}

@font-face {
  font-family: OrbitronRegular;
  src: url(./assets//fonts/Orbitron-Regular.ttf);
}

@font-face {
  font-family: PlayBold;
  src: url(./assets//fonts/Play-Bold.ttf);
}

@font-face {
  font-family: PlayRegular;
  src: url(./assets//fonts/Play-Regular.ttf);
}

@font-face {
  font-family: SFRegular;
  src: url(./assets//fonts/SFPRODISPLAYREGULAR.OTF);
}

@font-face {
  font-family: PPRader;
  src: url(./assets//fonts/PPRader-Regular.otf);
}

.blur::before {
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  width: contain;
  border-radius: 4px;
  padding: 0.3rem;
}

.blur {
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  width: contain;
  border-radius: 4px;
  padding: 0.3rem;
}

.title-red {
  background: linear-gradient(90.02deg, #c80e0e 18.25%, #ae1717 82.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.space {
  white-space: nowrap;
}

.btn-bsc-icon-loading {
  -webkit-animation: btnSpin 2s infinite linear;
  -moz-animation: btnSpin 2s infinite linear;
  -o-animation: btnSpin 2s infinite linear;
  -ms-animation: btnSpin 2s infinite linear;
  animation: btnSpin 2s infinite linear;
}

@-webkit-keyframes btnSpin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes btnSpin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes btnSpin {
  0% {
    -o-transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes btnSpin {
  0% {
    -ms-transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
  }
}

@keyframes btnSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


body.hidden {
  overflow-y: hidden;
}

.overFlow {
  overflow: hidden;
}

.height-content {
  min-height: calc(100vh - 325px);
}

.cus-pt-global {
  min-height: calc(100vh - 330px);
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
    background: transparent;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.rc-virtual-list-holder>div {
  background: #141718;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #9E77ED;
  border-radius: 50%;
  right: 8px;
  top: 50%;
  margin-top: -4px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
}

.ant-select-dropdown {
  padding: 4px !important;
  border-radius: 6px !important;
  background: #141718 !important;
  box-shadow: 0px 0px 19px 0px rgba(80, 0, 109, 0.18) !important;
}

.ant-select-item.ant-select-item-option {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Filter component */
.ant-select-dropdown .option-filter {
  padding: 10px;
  color: #667085;
  text-shadow: 0.5px 0.5px 0px rgba(6, 6, 31, 0.56);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all .25s;
  border-radius: 6px;
}

.ant-select-dropdown .option-filter:hover {
  border-color: #344054;
  color: #FCFCFD;
  background: #232627 !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #FCFCFD;
  font-weight: normal;
}

.ant-select-dropdown .rc-virtual-list-holder-inner .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  border-color: #344054;
  color: #FCFCFD;
  background: #232627 !important;
}

/* Datepicker */
.ant-picker-panel-container {
  border-radius: 12px !important;
  background: #141718 !important;
  box-shadow: 0px 0px 19px 0px rgba(80, 0, 109, 0.18) !important;
  padding: 16px;
}
.ant-picker-panel-container .ant-picker-header-view {
  font-size: 20px;
}
.ant-picker-panel-container .ant-picker-header,
.ant-picker-panel-container .ant-picker-panel {
  border-bottom: 0;
}
.ant-picker-panel-container .ant-picker-header,
.ant-picker-panel-container .ant-picker-content td {
  color: #F9FAFB;
  font-size: 12px;
}
.ant-picker-panel-container .ant-picker-cell-inner,
.ant-picker-date-panel .ant-picker-content th {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}
.ant-picker-date-panel .ant-picker-content th {
  color: #98A2B3;
}
.ant-picker-range-arrow {
  background: linear-gradient(135deg, transparent 40%, #9E77ED 40%) !important;
}
.ant-picker-range-arrow::before {
  background: linear-gradient(to left, #9E77ED 50%, #9E77ED 50%) no-repeat -5px -5px !important;
}
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background-color: transparent !important;
}
.ant-picker-panel-container .ant-picker-cell.ant-picker-cell-in-view,
.ant-picker-panel-container .ant-picker-cell {
  vertical-align: middle;
}
.ant-picker-panel-container .ant-picker-cell.ant-picker-cell-selected {
  border-radius: 8px;
  vertical-align: middle;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: transparent !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #B692F6 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: #7F56D9 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 0 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0 !important;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background-color: #7F56D9 !important;
  right: -6px !important;
  left: -6px !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-color: #7F56D9 !important;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #7F56D9 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: transparent !important;
}
.ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn {
  display: none;
}
.ant-picker-header-next-btn,
.ant-picker-header-prev-btn {
  display: flex;
  align-items: center;
}
.ant-picker-header-view button:hover {
  color: #7F56D9 !important;
}
@media screen and (max-width: 743px) {
  .ant-picker-panel-container {
    padding: 10px;
  }
  .ant-picker-dropdown {
    z-index: 150 !important;
  }
  .ant-picker-panel-container .ant-picker-panels {
    flex-wrap: unset !important;
    flex-direction: column;
    width: 100%;
  }
  .ant-picker-content {
    width: 100% !important;
    table-layout: auto !important;
  }
  .ant-picker-date-panel .ant-picker-body {
    width: 100%;
    padding: 0 !important;
  }
  .ant-picker-panel-container {
    width: 100%;
  }
  .ant-picker-date-panel {
    width: 100% !important;
  }
  .ant-picker-header-next-btn {
    position: absolute;
    right: 20px;
    top: 30px;
  }
}
/* Popover */
.ant-popover-content {
  max-width: 452px;
  border-radius: 16px;
  overflow: hidden;
}
@media screen and (max-width: 743px) {
  .ant-popover-content {
    max-width: 75vw;
    border-radius: 4.26vw;
  }
}